import * as THREE from 'three';

import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import truck from '../glb/truck.glb'
import roof from '../glb/roof.glb'
import rack from '../glb/rack.glb'
import driver_panel from '../glb/driver_panel.glb'
import passenger_panel from '../glb/passenger_panel.glb'

let camera, scene, renderer;
let cameraControls;
let effectController;
let ambientLight, light;

let expanded = false;	// force initialization
let bBottom;
let bLid;
let bBody;
let bFitLid;
let bNonBlinn;
let shading;

let passengerPanel, driverPanel;
const FULLY_RETRACTED = 138;
let teapot, textureCube;
const materials = {};

init();
render();

function init() {

  const container = document.createElement( 'div' );
  document.body.appendChild( container );

  const canvasWidth = window.innerWidth;
  const canvasHeight = window.innerHeight;

  // CAMERA
  camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 80000 );
  camera.position.set( - 600, 550, 1300 );

  // LIGHTS
  ambientLight = new THREE.AmbientLight( 0x333333 );

  light = new THREE.DirectionalLight( 0xFFFFFF, 1.0 );
  light.position.set( 0.32, 0.39, 0.7 );

  // RENDERER
  renderer = new THREE.WebGLRenderer( { antialias: true } );
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( canvasWidth, canvasHeight );
  renderer.outputEncoding = THREE.sRGBEncoding;
  container.appendChild( renderer.domElement );
  renderer.setClearColor( 0xffffff, 0);

  // EVENTS
  window.addEventListener( 'resize', onWindowResize );

  // CONTROLS
  cameraControls = new OrbitControls( camera, renderer.domElement );
  cameraControls.addEventListener( 'change', render );

  // TEXTURE MAP
  const textureMap = new THREE.TextureLoader().load( 'textures/uv_grid_opengl.jpg' );
  textureMap.wrapS = textureMap.wrapT = THREE.RepeatWrapping;
  textureMap.anisotropy = 16;
  textureMap.encoding = THREE.sRGBEncoding;

  // REFLECTION MAP
  const path = 'textures/cube/pisa/';
  const urls = [ 'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png' ];

  textureCube = new THREE.CubeTextureLoader().setPath( path ).load( urls );
  textureCube.encoding = THREE.sRGBEncoding;

  materials[ 'wireframe' ] = new THREE.MeshBasicMaterial( { wireframe: true } );
  materials[ 'flat' ] = new THREE.MeshPhongMaterial( { specular: 0x000000, flatShading: true, side: THREE.DoubleSide } );
  materials[ 'smooth' ] = new THREE.MeshLambertMaterial( { side: THREE.DoubleSide } );
  materials[ 'glossy' ] = new THREE.MeshPhongMaterial( { side: THREE.DoubleSide } );
  materials[ 'textured' ] = new THREE.MeshPhongMaterial( { map: textureMap, side: THREE.DoubleSide } );
  materials[ 'reflective' ] = new THREE.MeshPhongMaterial( { envMap: textureCube, side: THREE.DoubleSide } );

  // scene itself
  scene = new THREE.Scene();
  scene.background = new THREE.Color( 0xAAAAAA );

  scene.add( ambientLight );
  scene.add( light );

  const material2 = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );

  const loader = new GLTFLoader();

  loader.load( truck, function ( gltf ) {
    // gltf.scene.traverse( function ( child ) {
    //
    //   child.material = material2;
    //
    // } );
    gltf.scene.scale.setScalar(3);
    scene.add( gltf.scene );
    render();

  }, undefined, function ( error ) {

    console.error( error );

  } );

  loader.load( roof , function ( gltf ) {
    // gltf.scene.traverse( function ( child ) {
    //
    //   child.material = material2;
    //
    // } );
    gltf.scene.scale.setScalar(3);
    scene.add( gltf.scene );
    render();

  }, undefined, function ( error ) {

    console.error( error );

  } );

  loader.load( rack , function ( gltf ) {
    // gltf.scene.traverse( function ( child ) {
    //
    //   child.material = material2;
    //
    // } );
    gltf.scene.scale.setScalar(3);
    scene.add( gltf.scene );
    render();

  }, undefined, function ( error ) {

    console.error( error );

  } );

  loader.load( driver_panel , function ( gltf ) {
    // gltf.scene.traverse( function ( child ) {
    //
    //   child.material = material2;
    //
    // } );
    driverPanel = gltf
    driverPanel.scene.position.x = -FULLY_RETRACTED;
    gltf.scene.scale.setScalar(3);
    scene.add( gltf.scene );
    render();

  }, undefined, function ( error ) {

    console.error( error );

  } );

  loader.load( passenger_panel , function ( gltf ) {
    // gltf.scene.traverse( function ( child ) {
    //
    //   child.material = material2;
    //
    // } );
    passengerPanel = gltf;
    passengerPanel.scene.position.x = FULLY_RETRACTED;
    gltf.scene.scale.setScalar(3);
    scene.add( gltf.scene );
    render();

  }, undefined, function ( error ) {

    console.error( error );

  } );



  // GUI
  setupGui();

}

// EVENT HANDLERS

function onWindowResize() {

  const canvasWidth = window.innerWidth;
  const canvasHeight = window.innerHeight;

  renderer.setSize( canvasWidth, canvasHeight );

  camera.aspect = canvasWidth / canvasHeight;
  camera.updateProjectionMatrix();

  render();

}

function setupGui() {

  effectController = {
    newTess: 15,
    extended: false,
    lid: true,
    body: true,
    fitLid: false,
    nonblinn: false,
    newShading: 'glossy'
  };

  const gui = new GUI();
  gui.add( effectController, 'extended' ).name( 'extend panels' ).onChange( expandPanels );
}

function expandPanels() {
  if(effectController.extended && passengerPanel.scene.position.x > 0 ){
    requestAnimationFrame(expandPanels)
    passengerPanel.scene.position.x -= 5
    driverPanel.scene.position.x += 5
    renderer.render( scene, camera );
  }
  if(!effectController.extended && passengerPanel.scene.position.x < FULLY_RETRACTED ){
    requestAnimationFrame(expandPanels)
    passengerPanel.scene.position.x += 5
    driverPanel.scene.position.x -= 5
    renderer.render( scene, camera );
  }
}
//

function render() {

  // skybox is rendered separately, so that it is always behind the teapot.
  if ( shading === 'reflective' ) {

    scene.background = textureCube;

  } else {

    scene.background = null;

  }

  renderer.render( scene, camera );

}

